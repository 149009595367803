
* {
    font-family: 'Montserrat', sans-serif !important;
}

@media (min-width: 3024px) {
  h1 {
      font-size: 3rem !important; /* Увеличиваем размер заголовка */
  }
  h2 {
      font-size: 2rem !important; /* Увеличиваем размер подзаголовка */
  }
  h3 {
      font-size: 1.75rem !important; /* Увеличиваем размер для h3 */
  }
  p {
      font-size: 1.5rem !important; /* Увеличиваем размер текста абзаца */
  }
  span {
      font-size: 1.5rem !important; /* Увеличиваем размер текста для span */
  }
  a {
      font-size: 1.5rem !important; /* Увеличиваем размер текста ссылок */
  }
}

body {
  background-color: #f3f3f4 !important;
}

.bg-header {
  background-color: #fff;
}

.logo {
  width: 100%;
  height: auto;
  max-width: 189px; /* Максимальная ширина логотипа */
}

@media (max-width: 768px) {
  .logo {
      max-width: 150px; /* Ширина логотипа для мобильных устройств */
  }
}

@media (max-width: 576px) {
  .logo {
      max-width: 120px; /* Ширина логотипа для маленьких мобильных устройств */
  }
}

.text-color-gray{
  color: #7C8493;
}

.bg-gray {
  background-color: #f3f3f4 !important;
}

.bg-white {
  background-color: #f3f3f4;
}

.select-work {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.h-30 {
  height: 30%;
}

.fs-7 {
  font-size: 0.875rem; /* или любое другое значение, которое вам нужно */
}

.bs {
  border-radius: 16px;
}

.bs-table-bg {
  background-color: #4e4ff6;
}

.t-s-regular {
  font-size: 13px;
  font-weight: 400;
}

.t-s-medium {
  font-size: 13px;
  font-weight: 600;
}

.t-m-regular {
  font-size: 15px;
  font-weight: 400;
}

.t-m-medium {
  font-size: 15px;
  font-weight: 600;
}

.t-l-regular {
  font-size: 17px;
  font-weight: 400;
}

.t-l-medium {
  font-size: 17px;
  font-weight: 600;
}

h1 {
  font-size: 32px !important;
  font-weight: 700;
}

h2 {
  font-size: 30px !important;
  font-weight: 700;
}

h3 {
  font-size: 28px !important;
  font-weight: 700;
}

h4 {
  font-size: 20px !important;
  font-weight: 700;
}

.btn-gray {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 4px;
  fill: #ececed;
  color: black;
  border: none;
}
.btn-gray.small {
  height: 32px;
  border-radius: 40px;
  padding: 4px 8px;
}
.btn-gray.medium {
  height: 40px;
  border-radius: 40px;
  padding: 8px 12px;
}
.btn-gray.large {
  height: 52px;
  border-radius: 16px;
  padding: 14px 22px;
}
.btn-gray:default {
  background-color: #ececec;
}
.btn-gray:hover {
  background-color: #e0e0e0;
}
.btn-gray:active {
  background-color: #d9d9d9;
}

.btn-violet {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 4px;
  background-color: #4e4ff6;
  color: white;
  border: none;
}
.btn-violet.small {
  height: 32px;
  border-radius: 40px;
  padding: 4px 8px;
  padding-right: 8px;
}
.btn-violet.medium {
  height: 40px;
  border-radius: 40px;
  padding: 8px 12px;
}
.btn-violet.large {
  height: 52px;
  border-radius: 16px;
  padding: 14px 22px;
}
.btn-violet:default {
  background-color: #4e4ff5;
}
.btn-violet:hover {
  background-color: #4b4beb;
}
.btn-violet:active {
  background-color: #4949dd;
}

.btn-bright {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff;
  gap: 4px;
  color: #4E4FF6;
  border: none;
}
.btn-bright.small {
  height: 32px;
  border-radius: 40px;
  padding: 4px 8px;
}
.btn-bright.medium {
  height: 40px;
  border-radius: 40px;
  padding: 8px 12px;
}
.btn-bright.large {
  height: 52px;
  border-radius: 16px;
  padding: 14px 22px;
}
.btn-bright:default {
  background-color: #fff;
}
.btn-bright:hover {
  background-color: #f2f2f0;
}
.btn-bright:active {
  background-color: #ebe9f4;
}

.btn-bright-w-border {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff;
  gap: 4px;
  color: #4E4FF6;
  border-color: #4E4FF6;
}
.btn-bright-w-border.small {
  height: 32px;
  border-radius: 40px;
  padding: 4px 8px;
}
.btn-bright-w-border.medium {
  height: 40px;
  border-radius: 40px;
  padding: 8px 12px;
}
.btn-bright-w-border.large {
  height: 52px;
  border-radius: 16px;
  padding: 14px 22px;
}
.btn-bright-w-border:default {
  border-width: 1px;
  background-color: #fff;
}
.btn-bright-w-border:hover {
  background-color: #f2f2f0;
}
.btn-bright-w-border:active {
  background-color: #ebe9f4;
}

.form-check-input:checked {
  background-color: #4e4ff6 !important;
  border-color: #4e4ff6 !important;
}

.form-check-input:focus {
  border-color: rgba(78, 79, 246, 0.01) !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(78, 78, 246, 0.25) !important;
          box-shadow: 0 0 0 0.25rem rgba(78, 78, 246, 0.25) !important;
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #4e4ff6 !important;
  border-color: #4e4ff6 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: rgba(18, 18, 33, 0.0588235294) !important;
  border-color: rgba(78, 79, 246, 0.01) !important;
  outline: 0;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.form-control {
  font-family: Onest !important;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(18, 18, 33, 0.0588235294) !important;
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.input-group-text {
  font-family: Onest !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: rgba(18, 18, 33, 0.0588235294) !important;
  border: 1px solid rgba(18, 18, 33, 0.0588235294) !important;
  border-radius: 0.25rem;
}

.input-group {
  opacity: 80% !important;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100%;
}

.input-group:focus {
  opacity: 1 !important;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(18, 18, 33, 0.0588235294) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-select:focus {
  border-color: rgba(78, 79, 246, 0.01) !important;
  outline: 0;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #d9d9d9 !important;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}


.card-img-top {
  width: 80%;
  height: 80%;
  object-fit: contain;
  margin: 0 auto 20px auto;
}

.card-title {
  font-size: 1.25rem;
  font-weight: 500;
}

.card-text {
  color: #6c757d;
  font-size: 0.875rem;
}

.card{
  border-radius: 20px !important;
  width: 100%; 
}

.code-input {
  width: 20vw; /* Ширина инпута в процентах от ширины окна */
  height: 20vw; /* Высота инпута в процентах от ширины окна (чтобы он оставался квадратным) */
  max-width: 135px; /* Максимальная ширина */
  max-height: 135px; /* Максимальная высота */
  font-size: 2.5rem !important; /* Размер шрифта */
  border-radius: 10px; /* Скругление углов */
  border: 2px solid #dcdcdc; /* Цвет рамки */
  margin-right: 10px; /* Отступ справа */
  text-align: center; /* Центрируем текст */
}

/* Медиазапрос для больших экранов */
@media (min-width: 768px) {
  .code-input {
    width: 15vw; /* Уменьшаем ширину на больших экранах */
    height: 15vw; /* Уменьшаем высоту на больших экранах */
    font-size: 3rem !important; /* Увеличиваем размер шрифта */
    margin-right: 15px; /* Отступ справа */

  }
}

@media (min-width: 1200px) {
  .code-input {
    width: 10vw; /* Дополнительное уменьшение ширины */
    height: 10vw; /* Дополнительное уменьшение высоты */
    margin-right: 20px; /* Отступ справа */

    font-size: 3.5rem !important; /* Еще больше увеличиваем размер шрифта */
  }
}


.code-input.filled {
  border-color: #4e56ff;
}

.code-input:focus {
  outline: none;
  border-color: #4e56ff;
  box-shadow: 0 0 0 0.2rem rgba(78, 86, 255, 0.25);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f4f8;
}

.loading-spinner {
  text-align: center;
}

.spinner {
  width: 80px;
  height: 80px;
  border: 10px solid #ddd;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

.spinner-inner {
  width: 60px;
  height: 60px;
  border: 8px solid #ddd;
  border-top: 8px solid #8e44ad;
  border-radius: 50%;
  animation: spin 1s linear infinite reverse;
  position: relative;
  top: 10px;
  left: 10px;
}

.loading-text {
  font-size: 24px;
  font-weight: bold;
  color: #2c3e50;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.loading-animation {
  text-align: center;
  animation: fadeIn 1.5s ease-in-out infinite;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top-color: #4E4FF6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.loading-text {
  font-size: 2rem;
  color: #4E4FF6;
  margin-top: 20px;
  animation: textFade 1.5s ease-in-out infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeIn {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

@keyframes textFade {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; /* можно заменить на цвет фона вашего приложения */
}

.loading-animation {
  display: flex;
  font-size: 3rem;
  font-weight: bold;
  color: #4E4FF6;
  animation: bounce 2s infinite;
}

.letter {
  animation: bounce 1.5s infinite;
}

.letter:nth-child(2) {
  animation-delay: 0.1s;
}

.letter:nth-child(3) {
  animation-delay: 0.2s;
}

.letter:nth-child(4) {
  animation-delay: 0.3s;
}

.letter:nth-child(5) {
  animation-delay: 0.4s;
}

.letter:nth-child(6) {
  animation-delay: 0.5s;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.file-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4540de2f;
  border-radius: 10px;
  cursor: pointer;
}

.file-upload {
  border: 2px dashed #7B61FF;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.upload-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.upload-text {
  font-size: 14px;
  color: #7B61FF;
  margin-bottom: 5px;
}

.upload-subtext {
  font-size: 12px;
  color: #A3A3A3;
}

.custom-file-input {
  position: absolute;
  opacity: 0;
}

.custom-file-label {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: auto;
}

.content-box-mini{
  border: none;
  border-radius: 8px;
  padding: 10px;
}

.content-box-maps{
  border: none;
  border-radius: 8px;
  padding: 5px;
}

.form-control{
  font-family: 'Montserrat', sans-serif !important;
}

.uploaded-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploaded-image {
  max-width: 100%;
  max-height: 200px; /* Ограничьте высоту изображения */
  margin-bottom: 10px;
}

.custom-border-radius {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.form-control:focus {
  border-width: 2px !important;
  background-color: white !important;
  border-color: #2684ff !important;
  box-shadow: none !important;
  outline: 0;
}

.input-vacancys:focus{
  border-left-width: 2px;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
  border-right-width: 1px !important;
  background-color: #f3f3f4 !important;
  border-color: #dee2e6 !important;
  box-shadow: none !important;
}


.fade-in {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

img {
  user-select: none; /* Запретить выделение текста */
  pointer-events: none; /* Запретить взаимодействие с изображением */
  -webkit-user-drag: none; /* Запретить перетаскивание в WebKit-браузерах */
}